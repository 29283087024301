<template>
  <div>
    <!-- <v-sheet outlined color="primary" min-height="100vh" class="pt-10"> -->
      <v-container>
        <v-row justify="center">
          <v-card height="100" color="transparent" flat width="100"
            ><v-img src="camera.png" height="100" width="100" contain></v-img
          ></v-card>
        </v-row>
        <v-row justify="center" class="mt-5 fill-height">
          <v-col cols="12" class="text-center">
            <h1 class="contact-title  mt-10" :style="{color:titleFontColor}">
              GET IN TOUCH
            </h1>
            <p
               ref="contactMsg"
              :contenteditable="isLoggedIn"
              class="contact-text px-5 mx-auto mt-6"
              style="max-width: 600px"
              :style="{color:textFontColor}"
            >
              {{ dform.contactMsg }}
            </p>
            <a href="" class="text-decoration-none">
              <v-btn large outlined :style="{color:buttonFontColor}"> say HELLO </v-btn></a
            >
          </v-col>
        </v-row>

        <v-row justify="center" class="fill-height mt-15">
          <v-col cols="12" sm="6" md="4" class=" text-center">
             <v-card flat color="transparent" height="60" width="70" class="mx-auto" >
          <v-img :src="logo.image" width="100%" height="100%"></v-img>
             </v-card>
            <p class="contact-text"  ref="contactAbout" :contenteditable="isLoggedIn" :style="{color:textFontColor}">
              {{ dform.contactAbout }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="text-center">
            <h3 class="contact-subtitle" :style="{color:subtitleFontColor}">Address:</h3>
            <p class="contact-text"  ref="contactAddress" :contenteditable="isLoggedIn" :style="{color:textFontColor}">
              {{ dform.contactAddress }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="text-center">
            <h3 class="contact-subtitle"  :style="{color:subtitleFontColor}">Enquires:</h3>
            <p class="contact-text"  ref="contactPhone" :contenteditable="isLoggedIn" :style="{color:textFontColor}">
              {{ dform.contactPhone }}
            </p>
           <p class="contact-text" ref="contactEmail" :contenteditable="isLoggedIn" :style="{color:textFontColor}">
              {{ dform.contactEmail }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="isLoggedIn" class="mt-15 mr-md-15 mr-sm-6 text-end">
        <v-btn class="ml-2" @click="handleContactData" small bottom color="accent"> <b> save </b></v-btn>
      </div>
    <!-- </v-sheet> -->
     <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p class="secondary--text">{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon color="secondary">mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snackMixin } from '@/mixins'
import { apiClient } from "@/services";
import { mapGetters } from 'vuex';
export default {
   mixins: [snackMixin],
   props: {
    titleFontColor:{
      type:String,
    },
    subtitleFontColor:{
      type:String,
    },
    textFontColor:{
      type:String,
    },
    buttonFontColor:{
      type:String,
    },

  },
  data() {
    return {
          logo:{
           image:""
    },
      dform: {
        contactMsg:"",
        contactAbout: "",
        contactAddress: "",
        contactPhone: "",
        contactEmail: "",
      },
    };
  },

   created () {
    apiClient 
        .get("/pages/load-contact")
        .then((res) => {
            this.dform = res.data.contact
        })
        .catch((err) => {
          this.displayMsg(err.message, "error")
        });
        
        this.loadLogoHandler();
    },
    
  computed: {
    ...mapGetters([ "isLoggedIn"]),

  },
  
  methods: {
    async loadLogoHandler() {
      try {
        const { data } = await apiClient.get("/pages/load-logo");
        this.logo.title = data.logo.logoTitle
        this.logo.image = data.logo.logoImage
      } catch (error) {
        this.displayMsg(error.message, 'error')
      }
    },
   
    async handleContactData() {
      this.dform.contactMsg = this.$refs.contactMsg.innerText;
      this.dform.contactAbout = this.$refs.contactAbout.innerText;
      this.dform.contactAddress = this.$refs.contactAddress.innerText;
      this.dform.contactPhone = this.$refs.contactPhone.innerText;
      this.dform.contactEmail = this.$refs.contactEmail.innerText;
      try {
        const res = await apiClient.put('/pages/contact', {
          contactMsg: this.dform.contactMsg,
          contactAbout: this.dform.contactAbout,
          contactAddress: this.dform.contactAddress,
          contactPhone: this.dform.contactPhone,
          contactEmail: this.dform.contactEmail,
         
        })
        this.dform = res.data.contact
        this.displayMsg('Updated successfully', 'success')
      } catch (error) {
        this.displayMsg(error.message, 'error')
      }
    },
  },
};
</script>

<style scoped>
.contact-title{
  font-size: 45px;
}
.contact-subtitle{
font-size: 20px;
}
.contact-text{
font-size: 15px;
}

@media screen and (max-width:600px) {
  .contact-title{
  font-size: 30px;
  
}
.contact-subtitle{
font-size: 16px;
}
.contact-text{
font-size: 13px;
}


}
</style>

